import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import "./kontakt.scss"

function SignupForm() {
  return (
    <>
      <SEO
        title="Kontakt"
        description="Pozivamo Vas da nas kontaktirate
                o svemu šta Vas interesuje o Bachovim kapima, o svim
                Vašim nejasnoćama i nedoumicama vezanim za Bachove kapi."
      />
      <Layout>
        <article id="contact-container">
          <section className="contact-info">
            <address>
              <h1 className="about-h1">Kontaktirajte nas</h1>
              <p>
                Poštovani čitaoci našeg sajta, Pozivamo Vas da nam postavite
                pitanja o svemu šta Vas interesuje o Bachovim kapima, o svim
                Vašim nejasnoćama i nedoumicama vezanim za Bachove kapi. Таkođe,
                možete nam uputiti svoje komentare i iskustva.
              </p>
              <p>
                <strong> Kontakt informacije:</strong>
              </p>
              <p>Amila Damjanović</p>
              <p>Bahov praktičar, BFRP</p>
              <p className="mail-us">
                E-mail:{" "}
                <a href="mailto:amiladamjanovic@gmail.com">
                  amiladamjanovic@gmail.com
                </a>
              </p>
            </address>
            <p>
              Kontaktirajte nas ukoliko imate pitanja ili želite zakazati
              intervju sa Bahovim praktičarem.
            </p>
          </section>
        </article>
      </Layout>
    </>
  )
}

export default SignupForm

export const query = graphql`
  query MyQuery {
    file(relativePath: { eq: "earthRed.jpg" }) {
      childImageSharp {
        fluid(traceSVG: { background: "#fff", color: "#663399" }) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
